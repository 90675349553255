<template>
  <div class="dashboard-settings">
    <h1>Dashboard Settings</h1>

    <div
      v-for="editor in editors"
      :key="editor.key"
      class="dashboard-settings-section"
    >
      <label>
        <span v-on:click="showLightboxPreview(editor.lightbox)"
          >See preview</span
        >
        <span v-on:click="toggleEditor(editor)">Edit</span>
        {{ editor.title }}
      </label>
      <template>
        <ckeditor
          v-if="editor.show"
          :editor="ckeditorSettings.editor"
          v-model="dashboardSettings[editor.key]"
          :config="ckeditorSettings.editorConfig"
        ></ckeditor>
      </template>
    </div>

    <MercalaButton
      v-on:button-event="updateDashboardSettings()"
      label="Save settings"
      type="dashboard-ok"
    />
  </div>
</template>

<script>
import AngelaAPI from "@/utils/AngelaAPI";
import MercalaButton from "@/components/form/MercalaButton";
import { EventBus } from "@/utils/EventBus.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Lightboxes } from "@/utils/Constants";

export default {
  name: "DashboardSettings",
  components: {
    MercalaButton,
  },
  data() {
    return {
      editors: [
        {
          title: "Trial Expired Text",
          key: "trial_expired_text",
          lightbox: "trial",
          show: false,
        },
        {
          title: "Subscription Expired Text",
          key: "subscription_expired_text",
          lightbox: "expired",
          show: false,
        },
        {
          title: "Terms of Use",
          key: "terms_of_use",
          lightbox: "termsOfUse",
          show: false,
        },
        {
          title: "Privacy Policy",
          key: "privacy_policy",
          lightbox: "privacyPolicy",
          show: false,
        },
      ],
      ckeditorSettings: {
        editor: ClassicEditor,
        editorConfig: {
          removePlugins: [],
        },
      },
      dashboardSettings: {
        trial_expired_text: "",
        overdue_text: "",
        subscription_expired_text: "",
        terms_of_use: "",
        privacy_policy: "",
      },
    };
  },
  methods: {
    toggleEditor(editor) {
      editor.show = !editor.show;
    },
    showLightboxPreview(lightbox) {
      switch (lightbox) {
        case "trial":
          this.$emit("open-lightbox", {
            view: Lightboxes.Trial,
            data: { refreshText: true },
          });
          break;
        case "expired":
          this.$emit("open-lightbox", {
            view: Lightboxes.Expired,
            data: { refreshText: true },
          });
          break;
        case "termsOfUse":
          this.$emit("open-lightbox", {
            view: Lightboxes.Agreements,
            data: {
              terms: "termsOfUse",
              refreshText: true,
              showButtons: false,
            },
          });
          break;
        case "privacyPolicy":
          this.$emit("open-lightbox", {
            view: Lightboxes.Agreements,
            data: {
              terms: "privacyPolicy",
              refreshText: true,
              showButtons: false,
            },
          });
          break;
      }
    },
    closeLightbox() {
      this.showLightbox = false;
    },
    updateDashboardSettings() {
      AngelaAPI.updateDashboardSettings(this.dashboardSettings)
        .then((response) => {
          EventBus.$emit("show-notification", {
            title: "Success!",
            message: "Dashboard settings updated successfuly!",
            success: true,
            milliseconds: 3000,
          });
        })
        .catch((error) => {
          EventBus.$emit("show-notification", {
            title: "ERROR!",
            message:
              "Oh boy.. Jon messed up somewhere! Contact him if you see this",
            success: false,
            milliseconds: 3000,
          });
        });
    },
  },
  mounted() {
    this.$store.getters
      .getDashboardSettings(true)
      .then((settings) => {
        this.dashboardSettings = settings;
      })
      .catch((error) => {
        // Nothing to handle
      });

    EventBus.$on("open-lightbox", () => {
      this.openLightbox();
    });
    EventBus.$on("close-lightbox", () => {
      this.closeLightbox();
    });
  },
  destroyed() {
    EventBus.$off("open-lightbox", () => {
      this.openLightbox();
    });
    EventBus.$off("close-lightbox", () => {
      this.closeLightbox();
    });
  },
};
</script>

<style lang="scss">
.dashboard-settings {
  width: 100%;
  padding: 25px;

  h1 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 25px;
  }

  &-section {
    margin-bottom: 20px;

    label {
      display: inline-block;
      font-weight: 450;
      font-size: 0.875rem;
      margin-bottom: 15px;

      span {
        background-color: rgb(255, 30, 30);
        padding: 5px 8px;
        margin-right: 10px;
        border-radius: 15px;
        color: white;

        &:hover {
          cursor: pointer;
          background-color: rgba(255, 30, 30, 0.7);
        }
      }
    }

    .ck-editor {
      .ck-content {
        height: 400px;
        overflow-y: scroll;
      }
    }
  }
}
</style>
